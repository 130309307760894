<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.31209 4.25471C2.491 5.28116 2.00002 6.58316 2.00002 7.99984C2.00002 11.3135 4.68631 13.9998 8.00002 13.9998C9.4167 13.9998 10.7187 13.5089 11.7451 12.6878L3.31209 4.25471ZM4.2549 3.3119L12.688 11.745C13.509 10.7185 14 9.41651 14 7.99984C14 4.68613 11.3137 1.99984 8.00002 1.99984C6.58334 1.99984 5.28134 2.49082 4.2549 3.3119ZM0.666687 7.99984C0.666687 3.94975 3.94993 0.666504 8.00002 0.666504C12.0501 0.666504 15.3334 3.94975 15.3334 7.99984C15.3334 12.0499 12.0501 15.3332 8.00002 15.3332C3.94993 15.3332 0.666687 12.0499 0.666687 7.99984Z"
      fill="#FF2E2D"
    />
  </svg>
</template>

<style lang="scss" scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
