<template>
  <svg
    width="81"
    height="26"
    viewBox="0 0 81 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.6976 7H28.9392V11.0848C29.5152 10.3312 30.5088 9.8512 31.6944 9.8512C33.696 9.8512 35.1552 11.1952 35.1552 13.648V18.856H32.9136V13.9552C32.9136 12.5296 32.1264 11.7616 30.9408 11.7616C29.7216 11.7616 28.9392 12.5296 28.9392 13.9552V18.856H26.6976V7V7Z"
      fill="#191414"
    />
    <path
      d="M45.264 18.8559H43.0032V17.7327C42.4416 18.4863 41.4672 18.9663 40.3584 18.9663C38.2896 18.9663 36.8016 17.6223 36.8016 15.1839V9.98071H39.048V14.8671C39.048 16.2927 39.8352 17.0607 41.0208 17.0607C42.24 17.0607 43.008 16.2927 43.008 14.8671V9.98071H45.2688V18.8559H45.264Z"
      fill="#191414"
    />
    <path
      d="M50.5679 9.8368C51.7391 9.8368 52.8575 10.3792 53.4671 11.2144V7H55.7423V18.856H53.4671V17.5408C52.9391 18.3568 51.9455 19 50.5535 19C48.2927 19 46.5023 17.1568 46.5023 14.3872C46.4975 11.6128 48.2927 9.8368 50.5679 9.8368ZM51.1295 11.8048C49.9295 11.8048 48.7919 12.7024 48.7919 14.3824C48.7919 16.0624 49.9295 17.0272 51.1295 17.0272C52.3631 17.0272 53.4863 16.096 53.4863 14.416C53.4863 12.736 52.3631 11.8048 51.1295 11.8048Z"
      fill="#191414"
    />
    <path
      d="M61.032 9.8368C62.2032 9.8368 63.3216 10.3792 63.9312 11.2144V7H66.2064V18.856H63.9312V17.5408C63.4032 18.3568 62.4096 19 61.0176 19C58.7568 19 56.9664 17.1568 56.9664 14.3872C56.9616 11.6128 58.7568 9.8368 61.032 9.8368ZM61.5888 11.8048C60.3888 11.8048 59.2512 12.7024 59.2512 14.3824C59.2512 16.0624 60.3888 17.0272 61.5888 17.0272C62.8224 17.0272 63.9456 16.096 63.9456 14.416C63.9456 12.736 62.8224 11.8048 61.5888 11.8048Z"
      fill="#191414"
    />
    <path
      d="M68.0016 7H70.2432V18.856H68.0016V7Z"
      fill="#191414"
    />
    <path
      d="M75.9312 19C73.3536 19 71.4768 17.2048 71.4768 14.416C71.4768 11.6128 73.3008 9.83203 75.9312 9.83203C78.4944 9.83203 80.304 11.5792 80.304 14.224C80.304 14.512 80.2896 14.8 80.2416 15.088H73.752C73.8624 16.4032 74.76 17.1376 75.8832 17.1376C76.8432 17.1376 77.3712 16.6576 77.6592 16.0624H80.0784C79.5984 17.704 78.1248 19 75.9312 19ZM73.7664 13.5856H77.9808C77.9472 12.4144 77.0208 11.68 75.864 11.68C74.7936 11.68 73.944 12.3664 73.7664 13.5856Z"
      fill="#191414"
    />
    <path
      d="M22.3824 7H23.2272V7.2592H22.9728V8.1184H22.6368L22.6416 7.2592H22.3824V7V7ZM23.4048 7H23.8464L24.1152 7.72L24.408 7H24.8208V8.1232H24.5136L24.5088 7.4176L24.2208 8.1232H23.9952L23.7072 7.3936V8.1232H23.4096V7H23.4048Z"
      fill="#B9A096"
    />
    <path
      d="M16.2816 10.168V7H14.04V9.16C14.8272 9.4192 15.576 9.76 16.2816 10.168Z"
      fill="#B9A096"
    />
    <path
      d="M15.1632 15.2272C15.0816 15.1504 14.9952 15.0784 14.9136 15.0064C14.904 15.0016 14.8992 14.992 14.8896 14.9872C14.808 14.92 14.7264 14.8528 14.64 14.7856C14.6256 14.776 14.616 14.7664 14.6016 14.7568C14.52 14.6944 14.4336 14.632 14.3472 14.5744C14.3328 14.5648 14.3232 14.5552 14.3088 14.5504C14.2224 14.4928 14.1312 14.4352 14.04 14.3776L14.0352 14.3728H6.72964V7H4.48804V18.856H6.72964V16.6144H14.04V18.856H16.2816V16.5712C15.9648 16.0768 15.5856 15.6256 15.1632 15.2272Z"
      fill="#B9A096"
    />
    <path
      d="M18.5232 7V13.7104C16.5936 11.3488 13.6608 9.8416 10.3824 9.8416C9.50882 9.8416 8.65922 9.9472 7.84802 10.1488V12.4816C8.64482 12.2224 9.49922 12.0832 10.3824 12.0832C14.4336 12.0832 17.808 15.0064 18.5184 18.856H20.7696V7H18.5232Z"
      fill="#B9A096"
    />
    <path
      d="M2.2416 13.7104V7H0V18.856H2.2512C2.4432 17.8192 2.8272 16.8496 3.3648 15.9856V12.5344C2.9616 12.8992 2.5872 13.2928 2.2416 13.7104Z"
      fill="#B9A096"
    />
  </svg>
</template>

<style scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
