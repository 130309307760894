<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="expand">
      <path
        id="Icon"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4 5.41421L4 9C4 9.55229 3.55229 10 3 10C2.44772 10 2 9.55229 2 9L2 3C2 2.44772 2.44772 2 3 2L9 2C9.55228 2 10 2.44772 10 3C10 3.55228 9.55228 4 9 4L5.41421 4L9.70711 8.29289C10.0976 8.68342 10.0976 9.31658 9.70711 9.70711C9.31658 10.0976 8.68342 10.0976 8.29289 9.70711L4 5.41421ZM15 4C14.4477 4 14 3.55229 14 3C14 2.44772 14.4477 2 15 2H21C21.5523 2 22 2.44772 22 3V9C22 9.55229 21.5523 10 21 10C20.4477 10 20 9.55229 20 9V5.41421L15.7071 9.70711C15.3166 10.0976 14.6834 10.0976 14.2929 9.70711C13.9024 9.31658 13.9024 8.68342 14.2929 8.29289L18.5858 4H15ZM3 14C3.55229 14 4 14.4477 4 15L4 18.5858L8.29289 14.2929C8.68342 13.9024 9.31658 13.9024 9.70711 14.2929C10.0976 14.6834 10.0976 15.3166 9.70711 15.7071L5.41421 20H9C9.55228 20 10 20.4477 10 21C10 21.5523 9.55228 22 9 22H3C2.73478 22 2.48043 21.8946 2.29289 21.7071C2.10536 21.5196 2 21.2652 2 21L2 15C2 14.4477 2.44772 14 3 14ZM18.5858 20L14.2929 15.7071C13.9024 15.3166 13.9024 14.6834 14.2929 14.2929C14.6834 13.9024 15.3166 13.9024 15.7071 14.2929L20 18.5858V15C20 14.4477 20.4477 14 21 14C21.5523 14 22 14.4477 22 15V21C22 21.5523 21.5523 22 21 22H15C14.4477 22 14 21.5523 14 21C14 20.4477 14.4477 20 15 20H18.5858Z"
      />
    </g>
  </svg>
</template>

<style lang="scss" scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
