<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.66667 0.666504C3.03486 0.666504 3.33333 0.964981 3.33333 1.33317V1.59771C3.8184 1.4483 4.47216 1.33317 5.33333 1.33317C6.4623 1.33317 7.3978 1.70749 8.21045 2.03266C8.22286 2.03763 8.23524 2.04258 8.24759 2.04752C9.09299 2.38568 9.81287 2.6665 10.6667 2.6665C11.5915 2.6665 12.173 2.51262 12.5024 2.38085C12.6677 2.31473 12.7726 2.25298 12.8281 2.21597C12.8558 2.19754 12.8716 2.18496 12.8768 2.18064C13.0673 2.0016 13.3458 1.95005 13.5885 2.05058C13.8376 2.15377 14 2.39686 14 2.6665V9.99984C14 10.1766 13.9298 10.3462 13.8047 10.4712L13.3333 9.99984C13.8047 10.4712 13.8047 10.4712 13.8047 10.4712L13.8033 10.4727L13.8018 10.4741L13.7987 10.4772L13.7915 10.4842C13.7864 10.4891 13.7805 10.4946 13.7738 10.5006C13.7605 10.5127 13.7441 10.527 13.7246 10.5431C13.6854 10.5753 13.6336 10.6148 13.5677 10.6587C13.4357 10.7467 13.249 10.8516 12.9976 10.9522C12.4937 11.1537 11.7418 11.3332 10.6667 11.3332C9.5377 11.3332 8.6022 10.9588 7.78955 10.6337L7.75241 10.6188C6.90701 10.2807 6.18713 9.99984 5.33333 9.99984C4.40848 9.99984 3.82701 10.1537 3.49759 10.2855C3.43415 10.3109 3.3796 10.3356 3.33333 10.3585L3.33333 14.6665C3.33333 15.0347 3.03486 15.3332 2.66667 15.3332C2.29848 15.3332 2 15.0347 2 14.6665L2 10.0005V9.99916L2 2.66679L2 1.33317C2 0.964981 2.29848 0.666504 2.66667 0.666504ZM3.33333 3.02521L3.33333 8.93104C3.8184 8.78163 4.47216 8.6665 5.33333 8.6665C6.4623 8.6665 7.3978 9.04083 8.21045 9.36599L8.24759 9.38085C9.09299 9.71901 9.81287 9.99984 10.6667 9.99984C11.5915 9.99984 12.173 9.84595 12.5024 9.71419C12.5659 9.68881 12.6204 9.66407 12.6667 9.64114V3.7353C12.1816 3.88471 11.5278 3.99984 10.6667 3.99984C9.5377 3.99984 8.6022 3.62551 7.78955 3.30035C7.77714 3.29538 7.76476 3.29043 7.75241 3.28549C6.90701 2.94733 6.18713 2.6665 5.33333 2.6665C4.40848 2.6665 3.82701 2.82039 3.49759 2.95215C3.43415 2.97753 3.3796 3.00227 3.33333 3.02521Z"
      fill="#FF2E2D"
    />
    <path
      d="M3.33333 8.93104L3.33333 3.02521C3.3796 3.00227 3.43415 2.97753 3.49759 2.95215C3.82701 2.82039 4.40848 2.6665 5.33333 2.6665C6.18713 2.6665 6.90701 2.94733 7.75241 3.28549L7.78955 3.30035C8.6022 3.62551 9.5377 3.99984 10.6667 3.99984C11.5278 3.99984 12.1816 3.88471 12.6667 3.7353V9.64114C12.6204 9.66407 12.5659 9.68881 12.5024 9.71419C12.173 9.84595 11.5915 9.99984 10.6667 9.99984C9.81287 9.99984 9.09299 9.71901 8.24759 9.38085L8.21045 9.36599C7.3978 9.04083 6.4623 8.6665 5.33333 8.6665C4.47216 8.6665 3.8184 8.78163 3.33333 8.93104Z"
      fill="#FF2E2D"
    />
  </svg>
</template>
<style lang="scss" scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
