<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 1.33333C0 0.596954 0.596954 0 1.33333 0C2.06971 0 2.66667 0.596954 2.66667 1.33333C2.66667 2.06971 2.06971 2.66667 1.33333 2.66667C0.596954 2.66667 0 2.06971 0 1.33333ZM4.66667 1.33333C4.66667 0.596954 5.26362 0 6 0C6.73638 0 7.33333 0.596954 7.33333 1.33333C7.33333 2.06971 6.73638 2.66667 6 2.66667C5.26362 2.66667 4.66667 2.06971 4.66667 1.33333ZM9.33333 1.33333C9.33333 0.596954 9.93029 0 10.6667 0C11.403 0 12 0.596954 12 1.33333C12 2.06971 11.403 2.66667 10.6667 2.66667C9.93029 2.66667 9.33333 2.06971 9.33333 1.33333ZM0 6C0 5.26362 0.596954 4.66667 1.33333 4.66667C2.06971 4.66667 2.66667 5.26362 2.66667 6C2.66667 6.73638 2.06971 7.33333 1.33333 7.33333C0.596954 7.33333 0 6.73638 0 6ZM4.66667 6C4.66667 5.26362 5.26362 4.66667 6 4.66667C6.73638 4.66667 7.33333 5.26362 7.33333 6C7.33333 6.73638 6.73638 7.33333 6 7.33333C5.26362 7.33333 4.66667 6.73638 4.66667 6ZM9.33333 6C9.33333 5.26362 9.93029 4.66667 10.6667 4.66667C11.403 4.66667 12 5.26362 12 6C12 6.73638 11.403 7.33333 10.6667 7.33333C9.93029 7.33333 9.33333 6.73638 9.33333 6ZM0 10.6667C0 9.93029 0.596954 9.33333 1.33333 9.33333C2.06971 9.33333 2.66667 9.93029 2.66667 10.6667C2.66667 11.403 2.06971 12 1.33333 12C0.596954 12 0 11.403 0 10.6667ZM4.66667 10.6667C4.66667 9.93029 5.26362 9.33333 6 9.33333C6.73638 9.33333 7.33333 9.93029 7.33333 10.6667C7.33333 11.403 6.73638 12 6 12C5.26362 12 4.66667 11.403 4.66667 10.6667ZM9.33333 10.6667C9.33333 9.93029 9.93029 9.33333 10.6667 9.33333C11.403 9.33333 12 9.93029 12 10.6667C12 11.403 11.403 12 10.6667 12C9.93029 12 9.33333 11.403 9.33333 10.6667Z"
      fill="#191414"
    />
  </svg>
</template>

<style scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
