<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.5 3C5.5 2.72386 5.72386 2.5 6 2.5C6.27614 2.5 6.5 2.72386 6.5 3V7C6.5 7.27614 6.27614 7.5 6 7.5C5.72386 7.5 5.5 7.27614 5.5 7V3Z"
      fill="white"
    />
    <path
      d="M6.5 9C6.5 9.27614 6.27614 9.5 6 9.5C5.72386 9.5 5.5 9.27614 5.5 9C5.5 8.72386 5.72386 8.5 6 8.5C6.27614 8.5 6.5 8.72386 6.5 9Z"
      fill="white"
    />
  </svg>
</template>

<style lang="scss" scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
